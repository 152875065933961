import "./App.css";
import React from "react";
// import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Blogs from "./Blogs";
import Changelog from "./Changelog";
import PrivacyPolicy from "./PrivacyPolicy";
import NavBar from "./CustomNavBar";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AppIcon from './images/app_icon.png'


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/mobile/changelog",
    element: <Changelog />,
  }
,
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }
]);

function App() {
  return (
    <>
   
    <Navbar expand="lg" className="bg-body-tertiary Navbar">
        <Container>
          <Nav className="mx-auto my-2 my-lg-0" style={{ minWidth: '100%' }}>
            <Nav.Link href="/" className="mx-2 navbarItem"><img
              src={AppIcon}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Snacko logo"
            /> Snacko</Nav.Link>
            <Nav.Link href="/blogs" className="mx-2 navbarItem">Blogs</Nav.Link>
            <Nav.Link href="/mobile/changelog" className="mx-2 navbarItem">Changelog</Nav.Link>
            <Nav.Link href="/privacy-policy" className="mx-2 navbarItem">Privacy Policy</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <div className="App">
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
