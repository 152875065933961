import React from "react";
import './changelog.css'
function Changelog() {
  return (
    <div id="changelog">
   <ul>
   <li> 
    <h4>v 0.1.928 (22th Dec 2024)</h4>

    <ol>
     <li>multiple concurrent recipe creation fix</li>

    
 
    </ol>

    </li>
    <br />

     <li>multiple concurrent recipe creation fix</li>
   <li> 
    <h4>v 0.1.924 (27th March 2024)</h4>

    <ol>
     <li>Post adding ingredient to recipe flashes the screen</li>
     <li>updated loaders for logging page</li>
     <li>updated mathematics for nutrition calculation on product page</li>
     <li>Added more real estate for tap gesture on list items of search</li>
    
 
    </ol>

    </li>
    <br />

   <li> 
    <h4>v 0.1.922 (21th February 2024)</h4>

    <ol>
     <li>Search speed up from 15 seconds to 3 seconds. Thank you for your patience.</li>
     <li>Search all USDA and packaged products on the same page (3.44 million as of today)</li>

      <li> Now you can delete a consumption from the home page</li>
      <li> Previously Weight and height were unable to update, fixed</li>
 
    </ol>

    </li>
    <br />
   
  

   <li> 
    <h4>v0.1.919 (8th September 2023)</h4>

    <ol>
     <li>Create recipe - UI fixes</li>

      <li> Ingredients can now be deleted when creating a recipe</li>
  
      <li> Both packaged foods and recipes show up in consumption</li>
    </ol>

    </li>
    <br />


   <li> 
    <h4>v0.1.916 (20th August 2023) </h4>

    <ol>
     <li> Create custom recipe</li>

      <li> Searchable ingredients</li>
      <li> Added motion and haptics</li>
      <li> Edit Profile details</li>
    </ol>

    </li>
    <br />

    <li> 
    <h4>v0.1.911 (14th August 2023) </h4>

    <ol>
     <li> Fixed Label over flow on nutrition facts</li>
     <li> Added card based layout </li>
    </ol>

    </li>
    <br />


    <li> 
    <h4>v0.1.901 (8th August 2023) </h4>

    <ol>
     <li> Added micro-guage for better understanding of daily limit</li>

      <li> Shimmer loading to skeleton components</li>

      <li> Nutrition scores for easier understanding at an glance</li>
    </ol>

    </li>
    <br />

    <li> 
    <h4>v0.1.8(1st August 2023) </h4>

    <ol>
     <li> Nutrition Label on product page fixed</li>
    </ol>

    </li> 
    <br />
    <li> 
    <h4>v0.1.5(25th July 2023) </h4>

    <ol>
     <li> Added Graphs for visually understanding consumptions</li>
    </ol>

    </li>
    <br />



    <li> 
    
    <h4>v0.0.1 (12th July 2023)</h4>

    <ol>
      <li>  Set daily consumption limits</li>
      <li>  Text based search for 2.3m packaged products</li>
      <li>  Scan EAN-13 Barcode using camera</li>
    </ol>

    </li>

    <br />


    
   </ul>
    </div>
  );
}

export default Changelog;
