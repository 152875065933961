import React from "react";

import AppStore from './images/app_store.svg'
import PlayStore from './images/play_store.svg'
import Thali from './images/Thali.png'
import "./homepage.css";
import NavBar from "./CustomNavBar";

function HomePage() {
  return (
    <>
      


      <div className="Homepage">
        
        <img src={Thali} alt="Your SVG" style={{  height: "35vh" }} />

        <div id="paraText">
          <center>
            <b style={{ fontSize: "36pt" }}>Good Food, Good life</b>
            <br />
            <span style={{ fontSize: "18pt" }}>nutritional information for free</span>
          </center>
        </div>
        <br />
         
          <br />
        <center>
          <a href="mailto:ankit@snacko.health">
            <span className="testMsg">
              <u>feedback</u>
            </span>
          </a>
<br />
          <a href="https://apps.apple.com/us/app/snacko-it/id6450678993">
            <img src={AppStore} alt="Your SVG" style={{ width: "100px", height: "100px" }} />
          </a>
       <span>  </span>

          <a href="https://play.google.com/store/apps/details?id=health.snacko">
            <img src={PlayStore} alt="Your SVG" style={{ width: "100px", height: "100px" }} />
          </a>
        </center>
        <div className="btn-homepage">
          <p></p>
        </div>
      </div>
    </>
  );
}

export default HomePage;
