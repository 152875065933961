import React from 'react';

const PersonalSensitive = () => {
    return (
        <div >
        <h2> Do Not sell my personal data</h2><br />
        "Sale" means the exchange or transfer of personal and sensitive user data to a third party for monetary consideration.
    User-initiated transfer of personal and sensitive user data (for example, when the user is using a feature of the app to transfer a file to a third party, or when the user chooses to use a dedicated purpose research study app), is not regarded as sale.
    <br />
    <b> However, we do not sell any data to third parties</b>
    
    </div>
    );
};

export default PersonalSensitive;