
import React from "react";
import  "./PrivacyPolicy.css";
import Summary from "./privacy/summary";
import CoppaPrivacyPage from "./privacy/coppa";
import PersonalSensitive from "./privacy/personalSensitive";
import DoNotSellMyPersonalData from "./privacy/donotsellmydata";
import QuestionAnswer from "./privacy/questionAnswer";
import { useState } from "react";


function PrivacyPolicy() {
  return (
    <div className="privacy-main" id="privacyParent">
      <div></div>
      <div>
        <h1>Privacy Policy for Snacko</h1>
        <p>Last Updated on 22 December, 2024</p>
        <br />
        <span style={{ fontSize: "12pt" }}>
          This privacy notice for Sidkit Technologies Private Limited("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
         <br /> 
         <br /> 
         <b>
            1. Visit our website or use our mobile applications and if they point to this Privacy Policy.
          </b>
        </span>
      </div>
      <div>
        <br />
        <br />
       
          <Summary />

        <br />
        <br />
        <Collapsible title="Table of contents">
          <QuestionAnswer />
        </Collapsible>
        <br />
        <br />
        <Collapsible title="COPPA Privacy Page">
          <CoppaPrivacyPage />
        </Collapsible>
        <br />
        <br />
        <Collapsible title="Personal Sensitive Information">
          <PersonalSensitive />
        </Collapsible>
        <br />
        <br />
        <Collapsible title="Do Not Sell My Personal Data">
          <DoNotSellMyPersonalData />
        </Collapsible>
      </div>
    </div>
  );

  function Collapsible({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="collapsible">
        <span 
          onClick={() => setIsOpen(!isOpen)} 
          className="collapsible-header"
          style={{ cursor: "pointer" }}
        >
          {title}
          <span className={`collapsible-icon ${isOpen ? "open" : ""}`}>
            {isOpen ? "▼" : "►"}
          </span>
        </span>
        {isOpen && <div className="collapsible-content">{children}</div>}
      </div>
    );
  }
}

export default PrivacyPolicy;
