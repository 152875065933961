import React from 'react';

const DoNotSellMyPersonalData = () => {
    return (
        <div >
        <h2> Personal and Sensitive User Data</h2><br />
        We <b> do not </b> collect or store any personal data apart from weight, height birthdate, name and email.
    </div>
    );
};

export default DoNotSellMyPersonalData;