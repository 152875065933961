import React from 'react';

const Summary = () => {
    return (
        <div id="summaryKeyPoints">


        <h4>TLDR; Summary of everything below</h4><br />
        <ol>
        <li>
        Do we process any sensitive personal information?<br />No, We do not process sensitive personal information.
        </li>
        <br />
        <li>Do we receive any information from third parties?<br />No, We do not receive any information from third parties.
        </li>
        <br />
        <li>
        How do we process your information?<br />We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
        </li>
        
        <br />
        <li>
        In what situations and with which parties do we share personal information?<br />We do not share information in any situations and with specific third parties.
        </li>
        
        <br />
        <li>
        How do you exercise your rights?<br />The easiest way to exercise your rights is by contacting us at ankit@snacko.health. We will consider and act upon any request in accordance with applicable data protection laws.
        
        </li>
        
        <br />
        <li>
        How do we keep your information safe?<br />We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. We use services provided by<b> AWS and use TLS 2.0 encryption</b> for data in transit.
        </li>
           
        </ol>
        </div>
    );
};

export default Summary;